.imageRow {
  width: 100%;
}

.gridSystem {
  display: grid;
  align-items: center;
  height: 106%;
}

.imageRow__images {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  //flex-wrap: wrap;
}

.CommercialRowOne {
  display: flex;
  //flex-wrap: wrap;
  //height: 453px;
  justify-content: space-around;
  @media (min-width: 1000px) {
    padding-top: 2%;
  }

  @media (max-width: 450px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
}


.CommercialRowOne > img {
  @media (max-width: 450px) {
    //width: 100%;
    object-fit: contain;
  }
}

.CommercialRowOne > firstGrid > img {
  @media (max-width: 450px) {
    width: 40%;
    object-fit: contain;
  }
}

.Height {
  height: 1080px;
  @media (max-width: 340px) {
    padding-bottom: 676%;
  }

  @media (max-width: 390px) {
    padding-bottom: 577%;
  }


  @media (max-width: 420px) {
    padding-bottom: 600%;
  }
}

.CommercialText {
  color: white;
  width: 934px;
  height: 934px;
  text-align: center;
  display: grid;
  //justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 8% 0;
  @media (max-width: 450px) {

    position: relative;
    width: 794px;
    height: 794px;
    left: -237px;
    top: 1443px;
    margin-top: -137%;
    padding: 0 0 5% 0;
  }
}

.CommercialText > div {
  margin-top: -105%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.CommercialText > div > h1 {
  word-spacing: 18px;
}

.CommercialText > div > h1 > span {
  color: #AA8246;
  text-decoration: underline;
  //margin: 0 0 3% 0;
}

.CommercialText > div > h5 {
  line-height: 44.64px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 3% 0 0 0;

  @media (max-width: 450px) {
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
}

.CommercialInfo {
  margin: 139px 0 0 0;
  //display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 450px) {
    margin: 2364px 0 0 0;

  }
}

.noMargin__CommercialInfo {
  margin: 200px 0 0 0;
  padding-bottom: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 450px) {
    //margin: 2364px 0 0 0;
    padding-bottom: 15%;
  }

  @media (max-width: 340px) {
    padding-top: 80%;
    padding-bottom: 15%;

  }
}

.logoColor {
  width: 397px;
  height: 273px;

  @media (max-width: 450px) {
    width: 300px;
    height: 209px;
  }
}

.logoColorDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CommercialInfoText {
  margin: 30px 0 0 0;
  display: grid;
  text-align: center;
  align-items: center;
  color: white;

  @media (max-width: 450px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

.CommercialInfoText > h5 {
  //font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 228%;
  text-align: center;

  @media (max-width: 450px) {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 195%;
    text-align: center;
  }

}

.CommercialInfoText > h6 {
  margin: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 228%;
  text-align: center;
  letter-spacing: 0.05em;
}

.CommercialFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.CommercialFooter > img {
  width: 24%;
  height: 24%;

  @media (max-width: 450px) {
    width: 324px;
    height: 229px;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
  }
}


//////////////////////////////////
//Tech Page
/////////////////////////////////
.position {
  margin: 0 0 2% 0;
}

.position__image {
  //width: 863px;
  width: 699px;
  //height: 958px;
  height: 893px;
  left: 26px;
  top: 1704px;

  @media (max-width: 450px) {
    margin-top: 35%;
    width: 324px !important;
    height: 432px !important;
  }
}

.position__image__text {
  width: 83%;
  margin-left: -60px;

  @media (max-width: 450px) {
    width: 174%;
  }
}

.position__text {
  font-family: Montserrat;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-left: 38px;
  font-weight: normal;
  line-height: 27px;

  @media (max-width: 450px) {
    padding-left: 0;
  }
}

.position__text > p {
  width: 651px;
  height: 278px;
  left: 1020px;
  top: 2162px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 191.4%;

  @media (max-width: 450px) {
    width: 143%;
    height: 100%;
    margin-left: -18%;
  }
}

.position__quote {
  margin: 6% 0 6% 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.material {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    margin-bottom: -301px;

  }
}

.material__text {
  margin: 0 5% 1% 21%;
  width: 30%;
}

.material__text__para {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 191.4%;
  font-weight: 400;
  width: 75%;
  @media (max-width: 420px) {

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 191.4%;
    text-align: left;
    font-weight: 400;
    width: 260px;
    margin-left: -99%;
    margin-top: 348%;

  }
}

.quote {
  font-family: Prata;
  //width: 1254px;
  height: 60px;
  //text-align: center;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  //font-family: Prata;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 191.4%;

  @media (max-width: 450px) {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 191.4%;
    text-align: center;
  }
}

.quote__image {
  margin-left: auto;
  margin-right: auto;
  width: 125px;

  @media (max-width: 420px) {
    width: 60px;
  }
}

.material__text__header {
  width: 521px;
  height: 221.57px;
  left: 378px;
  top: 4410px;
}

.material__text__header > img {
  width: 90%;
  margin-left: -5%;
  @media (max-width: 420px) {
    width: 310px;
    margin-left: -25%;
    margin-top: 75%;
  }
}

#deal > span {
  //width: 130.25px;
  height: 47.16px;
  color: #C09553;
  text-align: left;
}

#different {
  text-align: center;
}

.material__image {
  @media (max-width: 420px) {
    margin-top: -455%;
  }
}

.material__image > img {
  width: 784px;
  height: 784px;
  left: 1137px;
  top: 4265px;
  @media (max-width: 450px) {
    width: 345px;
    height: 350px;
  }
}

.black {
  color: black;
}

#commercial__svg__one {
  margin-left: auto;
  margin-right: auto;
}

#commercial__svg__two {
  margin: -107% auto 0 auto;
}

.noFlex {
  flex: none !important;
}

#defaultExplore {
  margin-left: -14.3%;
}

#button__arrow {
  margin-left: -9.9%;
}