.progress {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5% 0;
  flex-wrap: wrap;
}

.progress__left > img {
  width: 602.12px;
  height: 168.86px;

  @media (max-width: 450px) {
    width: 280px;
    height: 90px;
  }
}

.progress__row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    display: grid;
  }
}

.progress__row > div {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  //width: 180px;

  @media (max-width:450px) {
    width: 70px;
    height: 70px;
    margin: 70px 0;
  }
}

.progress__row > div > span {
  margin: 38% 0 2% 0;
  font-size: 18px;
  //width: 61.72px;
  //height: 13.69px;
  //left: 50.49px;
  //top: 1433.31px;

  color: #000000;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #C09553 !important;
}

.CircularProgressbar {
  width: 88% !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: black !important;
  font-size: 33px !important;
}


.percentCerlce {
  width: 170px !important;
  height: 100px;
}

.progress__right {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 44%;
}

.progress__right__text {
  color: #606060;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  /* or 36px */
  word-spacing: 2px;
  text-align: left;
  //line-height: 36.48px;
  @media (max-width: 450px) {
    margin-top: 45%;
    width: 280px;
    //height: 90px;
  }
}
