* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.bgDark {
  background-color: #212121;
  padding-top: 0.9%;
  z-index: 55555555555555555;
  @media (max-width:450px) {
    height: 6728px;
  }
}

.bgDarkPersonal {
  background-color: #212121;
  padding-top: 0.9%;
}


.bgWhite {
  background-color: white;
  padding-top: 1%;
}

.header__container {
  height: 100vh;
  position: relative;
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: cover;
  width: 100%;
  //object-fit: contain;
 }

.commercial {
  background-color: #212121;
  //width: 100%;
  //height: 100vh;
  overflow-x: hidden;

  @media(max-width: 430px) {
    position: absolute;
    width: 100%;
  }
}

//////////////////////////////
//TECH PAGE
/////////////////////////////

.technical {
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  //background-image: url("");
  @media(max-width: 430px) {
    position: absolute;
  }
}

.position {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.personal {
  background-color: #212121;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  @media(max-width: 430px) {
    position: absolute;
  }
}

.header__container__text {
  font-family: Prata;
  color: white;
  text-align: center;
  font-size: 8rem;
  margin-top: 11%;
  line-height: 130px;
  letter-spacing: 0.17em;
  font-weight: normal;

  @media (max-width: 450px) {
    font-size: 2rem;
    margin-top: 28%;
  }
}
