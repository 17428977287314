#marginTop {
  @media (max-width: 450px) {
    margin-top: 96%;
  }
  }

.menuBot {
  @media (max-width: 450px) {
    margin-top: -25%;
    //margin-top: -25%;
  }
}

.noHeight {
  height: auto;
}
 #second__clock {
   @media (max-width: 450px) {
   position: absolute;
   width: 440px;
   object-fit: contain;
   height: 322px;
   margin-top: -5%;
   }
 }