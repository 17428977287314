.menuTop__left {
  @media only screen and (max-width: 480px) {
    margin-left: auto;
    margin-right: auto;
  }
}

#logo__top {
  @media only screen and (max-width: 450px) {
    margin-left: 4%;
  }
}

.navBar {
  display: flex;
  justify-content: center;
}

.menuTop {
  padding: 10px 0 0 0;
  margin: 25px 0;
  z-index: 666;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.navbar {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-bars {
  font-size: 2rem;
  background: none;
}

.menu-bars-color {
  color: rgb(192, 149, 83) !important;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #212121;
  width: 100%;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -110%;
  transition: 850ms;
  display: flex;
}

.nav-menu.active {
  right: 0;
  transition: 550ms;
  //position: fixed;

  @media (max-width: 450px) {
    position: absolute;
  }
}

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px 8px 16px;
  list-style: none;
  height: 40px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-weight: 400;
  font-size: 45px;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 8px;

  @media only screen and (max-width: 480px) {
    text-align: center;
  }
  @media only screen and (max-width: 380px) {
    text-align: center;
    font-size: 35px;
  }
}

.nav-list {
  margin: 50px;
  @media only screen and (max-width: 480px) {
    margin: 15px;
  }
}

.nav-text a:hover {
  background-color: white;
  color: #212121;

  @media only screen and (max-width: 480px) {
    background-color: white;
    color: #212121;
  }
}

.nav-menu-items {
  width: 100%;
  margin: 0;
  padding: 0;
}

.navbar-toggle {
  background-color: #212121;
  width: 95%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}

#nav-logo {
  top: -50px;
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 480px) {
    margin-right: 80px;
    top: 0;
    margin-top: -388px;
    margin-bottom: -329px;
  }
}

.nav-logo-menu {
  width: 10%;
  height: auto;
  margin-left: 44%;
  @media only screen and (max-width: 480px) {
    width: 62%;
    margin-left: 19%;
    margin-top: -56px;
  }

  @media only screen and (min-width: 768px) {
    width: 34%;
    height: auto;
    margin-left: 34%;
  }

  @media only screen and (min-width: 824px) {
    width: 19%;
    margin-left: 40.5%;
  }

}



