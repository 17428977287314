//Before Text In Commercial

//M2.jpg
.CommImageOne {
  //position: absolute;
  //width: 23%;
  height: 453px;
  //left: 16px;
  //top: 1104px;

  @media (max-width: 450px) {
    position: absolute;
    width: 58%;
    height: 187.87px;
    left: -3px;
    top: 593px;
  }
}

//Rossetto2b_1.jpg
.CommImageTwo {
  //position: absolute;
  //width: 21%;
  height: 453px;
  //left: 470px;
  //top: 1104px;

  @media (max-width: 450px) {

    position: absolute;
    width: 44%;
    height: 187px;
    left: 191px;
    top: 593px;
    object-fit: contain;

  }
}

//Social_02.jpg
.CommImageThree {
  //position: absolute;
  //width: 23%;
  height: 453px;
  //left: 883px;
  //top: 1104px;

  @media (max-width: 450px) {

    position: absolute;
    width: 187.87px;
    height: 187.87px;
    left: -3px;
    top: 793px;
  }
}

//Op_1.jpg
.CommImageFour {
  //position: absolute;
  //width: 23%;
  height: 453px;
  //right: 16px;
  //top: 1104px;
  @media (max-width: 450px) {
    position: absolute;
    //width: 129px;
    height: 187px;
    left: 191px;
    top: 793px;

  }
}

//5_Def.jpg
.CommImageFive {
  //position: absolute;
  width: 23.5%;
  height: 453px;
  margin-left: 6px;
  top: 1597px;

  @media (max-width: 450px) {
    position: absolute;
    width: 130.23px;
    height: 185.27px;
    left: -2px;
    top: 1226px;

  }
}

//Eye4.jpg
.CommImageSix {
  //position: absolute;
  width: 44.5%;
  height: 453px;
  left: 518px;
  top: 1597px;

  @media (max-width: 450px) {
    position: absolute;
    width: 100%;
    height: 221.17px;
    left: 0;
    top: 993px;
  }
}

//Malo_05.jpg
.CommImageSeven {
  //position: absolute;
  width: 23%;
  height: 453px;
  left: 1451px;
  top: 1597px;

  @media (max-width: 450px) {
    position: absolute;
    width: 185.27px;
    height: 185.27px;
    left: 138.73px;
    top: 1226px;
  }
}


//////////////////////////
//After Text In Commercial
//////////////////////////


//Mesauda_1.jpg
.CommImageSecondOne {
  width: 31.5%;
  height: 783px;
  left: 70px;
  top: 3314px;

  @media (max-width: 450px) {
    position: relative;
    width: 320px;
    height: 480px;
    left: 0px;
    top: 1481px;
  }
}

//H3.jpg
.CommImageSecondTwo {
  width: 628px;
  height: 385px;
  left: 625px;
  top: 3314px;

  @media (max-width: 450px) {
    position: relative;
    width: 358px;
    height: 207px;
    left: 0px;
    top: 1489px;
  }
}

//Profumo1.jpg
.CommImageSecondThree {
  width: 628px;
  height: 385px;
  left: 625px;
  margin-top: 16px;

  @media (max-width: 450px) {
    position: relative;
    width: 358px;
    height: 216px;
    left: 0px;
    top: 1482px;
  }
}

//B1.jpg
.CommImageSecondFour {
  width: 31.5%;
  height: 786px;
  left: 1290px;
  top: 3311px;

  @media (max-width: 450px) {
    position: relative;
    width: 321px;
    height: 451px;
    left: -3px;
    top: 1496px;
  }
}

//////////////////////////////

//Malo_17.jpg
.CommImageSecondFive {
  width: 604px;
  height: 517px;
  left: 100px;
  top: 4126px;
  margin-bottom: -50px;

  @media (max-width: 450px) {
    position: relative;
    width: 363px;
    height: 346px;
    left: -3px;
    top: 1380px;

  }
}

//Radiance_2.jpg
.CommImageSecondSix {
  width: 632px;
  height: 514px;
  left: 625px;
  top: 4134px;
  margin-bottom: -50px;

  @media (max-width: 450px) {
    position: relative;
    width: 325px;
    height: 269px;
    left: -0.5%;
    top: 917px;
  }
}

//Medavita_Adv_C.jpg
.CommImageSecondSeven {
  width: 602px;
  height: 1063px;
  left: 1250px;
  top: 4134px;

  @media (max-width: 450px) {
    position: relative;
    width: 323px;
    height: 614px;
    left: -2px;
    top: 237px;
  }
}

//Box_Penne_2800.jpg
.CommImageSecondEight {
  width: 604px;
  height: 521px;
  left: 70px;
  top: 4678px;

  @media (max-width: 450px) {
    position: relative;
    width: 363px;
    height: 346px;
    left: -3px;
    top: 1192px;

  }
}

//Aqua_-1.jpg
.CommImageSecondNine {
  width: 632px;
  height: 521px;
  left: 625px;
  top: 4678px;

  @media (max-width: 450px) {
    position: relative;
    width: 322px;
    height: 269px;
    left: 0.5%;
    top: 655px;
  }
}


////////////////////////////
//TECH PAGE
////////////////////////////

//.position__image {
//  //position: absolute;
//  width: 863px;
//  height: 958px;
//  left: 26px;
//  top: 1704px;
//}

.TechImageRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.TechImageRow > div {
  display: flex;
  margin-left: -1.9%;
  //display: flex;
  justify-content: space-between;
}

.TechImageRow > div > img {
  width: 453px;
  height: 453px;

  @media (max-width: 450px) {
    width: 160px;
    height: 160px;
    margin: 1% 1%;
  }
}

.TechImageRow > div > img:nth-child(2) {
  margin-left: 3%;
}

//.TechImageRow__below {
//  //pading-top: 20%;
//}

.TechImageRow > img {
  width: 453px;
  height: 453px;
  @media (max-width: 450px) {
    margin: 2% 0 0 0;
  }
}

#first__clock {
  @media (max-width: 450px) {
    width: 464px;
    object-fit: contain;
    height: 338px;
    margin-top: -115.5%;
    margin-left: -9px;
    margin-right: auto;
  }
}

ond__clock {
  @media (max-width: 450px) {
    position: absolute;
    width: 453px;
    height: 300px;
    margin-top: -5%;
  }
}

.TechImageRow__below {

  @media (max-width: 450px) {
    padding-top: 107%;
  }
}


